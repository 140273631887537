.service-letter {
  font-family: Arial, sans-serif;
  font-size: 23px;
  line-height: 1.35;
  padding: 10px;
  max-width: 299mm; 
  height: 420mm; 
  position: relative;
  display: flex;
  flex-direction: column; 
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.company-logo {
  max-height: 50px;
  position: absolute;
  top: 0;
  right: 0.5rem;
}

.corner-image {
  position: absolute;
}

.top-corner {
  top: 0;
  right: 0;
  width: 400px; 
  height: auto; 
}

.bottom-corner {
  bottom: 0;
  left: 0;
  width: 400px;
  height: auto; 
}

.right-bottom-corner {
  bottom: 0px;
  right: 0px;
  width: 300px; /* Adjust width as needed */
  height: 290px; /* Adjust height as needed */
}

.footer {
  text-align: center;
  margin-top: 0px;
}

.mt-5 {
  margin-top: 3rem !important;
}

.btn {
  margin-top: 1rem;
}

.container {
  max-width: 800px;
  margin: 0 auto;
}

